<template>
   <route-view></route-view>
</template>

<script>
import RouteView from "@/layout/RouteView";
export default {
  name: 'Index',
  components:{
      RouteView
  },
  props:{
  },
  data() {
    return {

    }
  },
  created() {
    this.loadData()
  },
  methods:{
    loadData() {}
  },
 }
</script>

<style scoped>

</style>